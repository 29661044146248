<script setup lang="ts">
    
    
    const year = new Date().getFullYear()
    const form = ref({
        email: '',
        password: '',
        code: '',
        isSave: true
    })
</script>
<template>
    <footer class="fixed bottom-0 bg-brand-500 px-6 pt-2 w-full text-slate-300 flex justify-between">
        <p>&copy; {{ year }} DBBS | v 1.3.2</p>
        <p>Powered by DBBS Solutions Center</p>
    </footer>
</template>